import {Box, Grid, Typography} from '@material-ui/core'
import LeftBar from 'trello-shared-resources/dist/components/leftBar/LeftBar'
import React, {useState} from 'react'
import {MenuEnum} from '../../types/MenuEnum'
import EmailIcon from '@atlaskit/icon/glyph/email'
import {getMenuContent} from 'trello-shared-resources/dist/services/MenuService'
import {ButtonItem} from '@atlaskit/side-navigation'
import {useStyles} from './UnsubscribeDisplayStyles'
import Unsubscribe from './Unsubscribe'
import {UnsubscribeProps} from '../../types/UnsubscribeProps'
import {
    emailNotificationSettingTitle,
    unsubscribePageTitle
} from '../emailNotifications/onboarding/email-notifications-messages'

const UnsubscribeDisplay = (props: UnsubscribeProps) => {

    const classes = useStyles()
    const [selectedMenuItem, setSelectedMenuItem] = useState<string>(MenuEnum.NOTIFICATION.toString())

    const menuButtons = [
        <ButtonItem isSelected={selectedMenuItem === MenuEnum.NOTIFICATION.toString()}
                    iconBefore={<EmailIcon label={emailNotificationSettingTitle}/>}
                    key={MenuEnum.NOTIFICATION}
                    onClick={() => setSelectedMenuItem(MenuEnum.NOTIFICATION.toString())}>
            {emailNotificationSettingTitle}
        </ButtonItem>
    ]

    let mainDialogContent = getMenuContent(null, selectedMenuItem.toString())
    if (!mainDialogContent && selectedMenuItem === MenuEnum.NOTIFICATION.toString()) {
        mainDialogContent = <Unsubscribe {...props}/>
    }

    return (
        <Box className={classes.container}>
            <Typography className={classes.title}>{unsubscribePageTitle}</Typography>
            <Grid container className={classes.gridContainer}>
                <LeftBar selectedMenuItem={selectedMenuItem.toString()} setSelectedMenuItem={setSelectedMenuItem}
                         externalButtons={menuButtons} hideManageMyAccount={true}/>
                <Grid item xs={8} md={10} xl={9} className={classes.contentContainer}>
                    {mainDialogContent}
                </Grid>
            </Grid>
        </Box>
    )
}

export default UnsubscribeDisplay