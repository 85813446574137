import React from 'react'
import { Box, Grid, Link, Typography } from '@material-ui/core'

import {
    emailNotificationsDismissBody1,
    emailNotificationsDismissBody2,
    emailNotificationsDismissBody3,
    emailNotificationsDismissBody4,
    emailNotificationsDismissButton,
    emailNotificationsDismissSubtitle,
    emailNotificationsDismissTitle
} from '../email-notifications-messages'
import Button from '@atlaskit/button'
import { useStyles } from './EmailNotificationsDimissStyles'
import { SuccessOrDismissOnboardingProperties } from '../../../../types/SuccessOrDismissOnboardingProperties'
import { settingsClickHandler } from '../EmailNotificationsUtils'

export const EmailNotificationsDismiss = (props: SuccessOrDismissOnboardingProperties) => {
    const classes = useStyles()

    return (
        <Grid item xs={7}>
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center"
                  className={classes.container}>
                <Grid item xs={6}>
                    <Typography className={classes.title}>{emailNotificationsDismissTitle}</Typography>
                    <Typography className={classes.subtitle}>{emailNotificationsDismissSubtitle}</Typography>
                    <Box className={classes.content}>
                        <Typography>
                            {emailNotificationsDismissBody1}
                            <Link
                                onClick={() => settingsClickHandler('email_dismiss', props.licenseDetails.trelloIframeContext)}>{emailNotificationsDismissBody2}</Link>
                            {emailNotificationsDismissBody3}
                        </Typography>
                        <Typography className={classes.secondParagraph}>{emailNotificationsDismissBody4}</Typography>
                    </Box>
                    <Button appearance="primary" type="submit"
                            onClick={() => props.takeMeToMyBoardClickHandler('email_dismiss')}>{emailNotificationsDismissButton}</Button>
                </Grid>

            </Grid>
        </Grid>
    )
}

export default EmailNotificationsDismiss
