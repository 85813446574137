import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
        container: {
            overflow: 'hidden',
            minWidth: 400,
            '@media (max-width:550px)': {
                overflow: 'display',
            }
        },
        gridContainer: {
            flexWrap: 'nowrap',
            '& > div:first-child': {
                height: 'calc(100vh - 60px)',
                padding: '0px 17px 26px 17px',
                '@media (max-width:550px)': {
                    maxWidth: '43%',
                    padding: 0
                },
                '@media (min-width: 960px)': {
                    maxWidth: '20%',
                },
                '& > nav': {
                    minWidth: '212px'
                }
            },
            '& > div:nth-child(2)': {
                '@media (max-width:500px)': {
                    paddingRight: 5
                },
            }
        },
        contentContainer: {
            backgroundColor: 'white',
            height: 'calc(100vh - 60px)',
            padding: '24px 48px 24px 48px',
            '@media (max-width:500px)': {
                height: 'auto',
            }
        },
        title: {
            fontFamily: 'SF Pro Display Bold',
            textAlign: 'center',
            fontSize: 24,
            marginTop: 16,
            marginBottom: 15,
            color: '#172B4D',
            lineHeight: '29px'
}
    }
)


export {useStyles}
