import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import IconWithTitle from '../commonComponents/IconWithTitle'
import {
    emailNotificationsStoredBody8,
    emailNotificationsTitle,
    returnToTrelloButtonText
} from '../onboarding/email-notifications-messages'
import OnboardingProperties from 'trello-shared-resources/dist/types/OnboardingProperties'
import SuccessMessage from '../commonComponents/SuccessMessage'
import { useStyles } from './MiniOnboardingSuccessStyles'
import Button from '@atlaskit/button'

export const MiniOnboardingSuccess = (props: OnboardingProperties) => {
    const classes = useStyles()

    const returnToTrelloHandler = () => {
        props.licenseDetails.trelloIframeContext.closeModal()
    }

    return (
        <Grid container spacing={0} className={classes.grid}>
            <IconWithTitle title={emailNotificationsTitle}/>
            <SuccessMessage {...props}/>
            <Box>
                <Typography className={classes.secondParagraph}>{emailNotificationsStoredBody8}</Typography>
            </Box>
            <Box alignContent="flex-end">
                <Button appearance="primary" type="submit"
                        onClick={returnToTrelloHandler}>{returnToTrelloButtonText}</Button>
            </Box>
        </Grid>
    )
}

export default MiniOnboardingSuccess