import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useStyles } from './CommonComponentsStyles'
import { emailNotificationsBody1, emailNotificationsInfoMessage } from '../onboarding/email-notifications-messages'
import InfoIcon from '@atlaskit/icon/glyph/info'

/**
 * Common component: Section Message Text to explain Trello restrictions
 */
const RestrictionsSectionMessageText = () => {
    const classes = useStyles()

    return (
        <>
            <Typography className={classes.content}>
                {emailNotificationsBody1}
            </Typography>
            <Grid container spacing={1}>
                <Grid item xs="auto">
                    <InfoIcon label="info" primaryColor="#42526E" size="medium"/>
                </Grid>
                <Grid item xs>
                    <Typography className={classes.infoMessage}>
                        {emailNotificationsInfoMessage}
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}

export default RestrictionsSectionMessageText