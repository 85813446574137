import {Grid, Link, Typography} from '@material-ui/core'
import {Skeleton} from 'antd'
import React, {useEffect, useMemo, useState} from 'react'
import {useStyles} from './UnsubscribeStyles'
import {
    emailNotificationSettingActiveStatus,
    emailNotificationSettingInactiveStatus,
    emailNotificationSettingTitle,
    emailNotificationsTitle,
    unsubscribeErrorBody,
    unsubscribeErrorLinkText,
    unsubscribeErrorTitle,
    unsubscribeFeedbackLoadingErrorBody1,
    unsubscribeFeedbackLoadingErrorBody2,
    unsubscribeFeedbackLoadingErrorBody3,
    unsubscribeFeedbackLoadingErrorBody4,
    unsubscribeFeedbackLoadingErrorLinkText1,
    unsubscribeFeedbackLoadingErrorLinkText2,
    unsubscribeFeedbackLoadingErrorTitle, unsubscribeGoToTrello, unsubscribeGoToTrelloBoard,
    unsubscribeSuccessfullyBody,
    unsubscribeSuccessfullyTitle,
    unsubscribeUnsubscribedBody1,
    unsubscribeUnsubscribedBody2
} from '../emailNotifications/onboarding/email-notifications-messages'
import NotificationUpdateErrorMessage from '../settings/notificationSettings/NotificationUpdateErrorMessage'
import Lozenge from '@atlaskit/lozenge'
import NotificationUnsubscribeSection from '../settings/notificationSettings/NotificationUnsubscribeSection'
import {UnsubscribeProps} from '../../types/UnsubscribeProps'
import {getUserEmailForNotifications} from '../../modules/Persistence'
import {UserEmailNotificationStored} from '../../types/UserEmailNotificationStored'
import UnsubscribeResultMessage from './UnsubscribeSuccessMessage'
import UnsubscribeFeedbackForm from './UnsubscribeFeedbackForm'
import UnsubscribeFeedbackSuccess from './UnsubscribeFeedbackSuccess'
import Button from '@atlaskit/button'


const Unsubscribe = (props: UnsubscribeProps) => {

    const classes = useStyles()

    const {memberId, emailSubscriptionId, boardId} = props
    
    const [userEmail, setUserEmail] = useState<string | undefined>('')
    const [isLoading, setIsLoading] = useState(true)
    const [successUpdate, setSuccessUpdate] = useState(false)
    const [successFeedbackForm, setSuccessFeedbackForm] = useState(false)
    const [errorUpdate, setErrorUpdate] = useState(false)

    const isEmailsNotificationsActive = userEmail !== undefined && userEmail !== ''

    const trelloIframeContext = useMemo(() => ({
        getContext: () => ({member: memberId}),
        jwt: () => ''
    }), [memberId])

    useEffect(() => {
        if (memberId && emailSubscriptionId) {
            getUserEmailForNotifications(trelloIframeContext, emailSubscriptionId).then((result: UserEmailNotificationStored) => {
                setUserEmail(result.email || '')
                setIsLoading(false)
            }).catch(() => {
                setIsLoading(false)
                setErrorUpdate(true)
            })
        }
    }, [memberId, emailSubscriptionId, trelloIframeContext])

    if (!memberId || !emailSubscriptionId)
        return <NotificationUpdateErrorMessage setErrorUpdate={setErrorUpdate} closable={false}
                                               titleErrorMessage={unsubscribeFeedbackLoadingErrorTitle}>
            <>
                {unsubscribeFeedbackLoadingErrorBody1}
                <Link href='https://trello.com' target='_blank'>{unsubscribeFeedbackLoadingErrorLinkText1}</Link>
                {unsubscribeFeedbackLoadingErrorBody2}
                <Link href={process.env.REACT_APP_DOCUMENTATION_URL} target='_blank'>{unsubscribeFeedbackLoadingErrorLinkText2}</Link>
                {unsubscribeFeedbackLoadingErrorBody3}
                <Link href={process.env.REACT_APP_TRELLO_APP_SUPPORT_LINK} target='_blank'>{unsubscribeFeedbackLoadingErrorLinkText1}</Link>
                {unsubscribeFeedbackLoadingErrorBody4}
            </>
        </NotificationUpdateErrorMessage>

    if (isLoading) {
        return <Skeleton active={true}/>
    }


    return <Grid item md={8} xs={12}>
        <Typography variant={'h1'} className={classes.title}>{emailNotificationSettingTitle}</Typography>

        {successUpdate && <UnsubscribeResultMessage appearance='success' title={unsubscribeSuccessfullyTitle}
                                                    body={unsubscribeSuccessfullyBody}
                                                    linkText={unsubscribeUnsubscribedBody2}
                                                    link={process.env.REACT_APP_DOCUMENTATION_URL!}/>}
        {errorUpdate &&
            <UnsubscribeResultMessage appearance={'error'} title={unsubscribeErrorTitle} body={unsubscribeErrorBody}
                                      linkText={unsubscribeErrorLinkText}
                                      link={process.env.REACT_APP_TRELLO_APP_SUPPORT_LINK!}/>}

        {!successUpdate &&
            <Typography variant={'h2'} className={classes.subtitle}>
                {emailNotificationsTitle}
                <Lozenge appearance={isEmailsNotificationsActive ? 'success' : 'removed'} isBold>
                    {isEmailsNotificationsActive ? emailNotificationSettingActiveStatus : emailNotificationSettingInactiveStatus}
                </Lozenge>
            </Typography>
        }

        {isEmailsNotificationsActive &&
            <NotificationUnsubscribeSection trelloIframeContext={trelloIframeContext} userEmail={userEmail}
                                            setUserEmail={setUserEmail}
                                            setErrorUpdate={setErrorUpdate} setSuccessUpdate={setSuccessUpdate}
                                            emailSubscriptionId={emailSubscriptionId}
                                            setSuccessFeedbackForm={setSuccessFeedbackForm}/>
        }

        {!isEmailsNotificationsActive && !successUpdate && <>
                <Typography className={[classes.emailNotificationText, classes.emailNotificationUnsubscribeText].join(' ')}>
                    {unsubscribeUnsubscribedBody1}
                    <Link href={process.env.REACT_APP_DOCUMENTATION_URL} target='_blank'>{unsubscribeUnsubscribedBody2}</Link>{unsubscribeFeedbackLoadingErrorBody4}
                </Typography>
                {emailSubscriptionId && <Grid container className={classes.buttonsContainer} justifyContent={'flex-end'}>
                    <Button appearance="primary"
                            href={boardId ? `https://trello.com/b/${boardId}` : 'https://trello.com'}>{boardId ? unsubscribeGoToTrelloBoard : unsubscribeGoToTrello}</Button>
                </Grid>}
            </>
        }

        {!isEmailsNotificationsActive && successUpdate && !successFeedbackForm &&
            <UnsubscribeFeedbackForm setSuccessFeedbackForm={setSuccessFeedbackForm} trelloIframeContext={trelloIframeContext}
                                     emailSubscriptionId={emailSubscriptionId} boardId={boardId}/>}
        {!isEmailsNotificationsActive && successUpdate && successFeedbackForm && <UnsubscribeFeedbackSuccess/>}
    </Grid>
}

export default Unsubscribe