import { makeStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'


export const useStyles = makeStyles(() =>
    createStyles({
        container: {
            minHeight: '100vh',
            '& button[type=submit]': {
                fontFamily: 'SF UI Text Bold',
                float: 'right'
            }
        },
        title: {
            fontSize: 24,
            fontFamily: 'SF UI Text Regular',
            color: '#172B4E',
            letterSpacing: 0,
            lineHeight: '29px'
        },
    })
)
