import {Typography} from '@material-ui/core'
import {
    emailNotificationsEmailText,
    emailNotificationSettingBody4,
    emailNotificationSettingBody5,
    emailNotificationSettingEmailLabel,
    emailNotificationSettingHelpTextNewEmail,
    emailNotificationSettingInvalidEmail,
    emailNotificationSettingMandatoryEmail,
    emailNotificationSettingMandatoryEULA,
    emailNotificationSettingSubmitButton,
    emailNotificationsEulaText
} from '../../emailNotifications/onboarding/email-notifications-messages'
import Form, {CheckboxField, ErrorMessage, Field, FormFooter, HelperMessage} from '@atlaskit/form'
import Textfield from '@atlaskit/textfield'
import EditIcon from '@atlaskit/icon/glyph/edit'
import {Checkbox} from '@atlaskit/checkbox'
import Button from '@atlaskit/button'
import React, {useState} from 'react'
import {useStyles} from './NotificationSettingsStyles'
import {storeUserEmailForNotifications} from '../../../modules/Persistence'
import {
    EmailNotificationEventType,
    trackEmailNotificationEvent
} from '../../emailNotifications/onboarding/EmailNotificationsUtils'
import * as Sentry from '@sentry/browser'
import {NotificationSettingsErrorsForm} from '../../../types/NotificationSettingsErrorsForm'
import {NotificationSettingsFormProperties} from '../../../types/NotificationSettingsFormProperties'
import {emailValidation} from 'trello-shared-resources/dist/modules/utility/Validations'
import RestrictionsSectionMessageText from '../../emailNotifications/commonComponents/RestrictionsSectionMessageText'

const NotificationSettingsForm = (props: NotificationSettingsFormProperties) => {

    const classes = useStyles()

    const [editingEmail, setEditingEmail] = useState(false)
    const [emailTouched, setEmailTouched] = useState(false)
    const [errors, setErrors] = useState<NotificationSettingsErrorsForm>({email: undefined, eula: undefined})

    const trelloIframeContext = props.trelloIframeContext
    const isEmailsNotificationsActive = props.userEmail !== undefined && props.userEmail !== ''

    /**
     * Validate if the email field has the properly format
     * @param value the mail introduced by the user
     * @return the validation error or undefined if it has a valid email
     */
    const validateEmailHandler = (value: string | undefined) => {
        let resultValidateEmail = undefined
        if (!value) resultValidateEmail = emailNotificationSettingMandatoryEmail
        if (value && emailValidation(value) !== undefined) resultValidateEmail = emailNotificationSettingInvalidEmail
        setErrors({...errors, email: resultValidateEmail})
        return resultValidateEmail
    }

    /**
     * Validate if the eula checkbox has been checked
     * @param value true if the checkbox has been checked or undefined otherwise
     * @return the validation error or undefined if it has been checked
     */
    const validateEULAHandler = (value: boolean | undefined) => {
        const resultValidateEULA = !value ? emailNotificationSettingMandatoryEULA : undefined
        setErrors({...errors, eula: resultValidateEULA})
        return resultValidateEULA
    }

    /**
     * Validate the form and if it's fine
     * @param data filled by the user on the form
     * @return an object with the error messages if the form is not valid or an empty object
     */
    const updateEmailHandler = async (data: { email: string, eula: boolean }) => {
        const errors = {
            eula: validateEULAHandler(data.eula),
            email: validateEmailHandler(data.email)
        }
        setErrors(errors)
        if (errors.email || errors.eula) return errors

        props.setErrorUpdate(false)
        props.setSuccessUpdate(false)
        storeUserEmailForNotifications(trelloIframeContext, data.email, props.userEmail !== undefined && props.userEmail !== '')
            .then(result => {
                trackEmailNotificationEvent(trelloIframeContext.getContext(), props.userEmail ? EmailNotificationEventType.EDIT : EmailNotificationEventType.ADD)
                props.setSuccessUpdate(true)
                props.setUserEmail(data.email)
                props.setSuccessFeedbackForm(false)
            })
            .catch(error => {
                Sentry.captureException(error)
                props.setErrorUpdate(true)
            })
        return {}
    }

    return <>
        {isEmailsNotificationsActive &&
        <>
            <Typography variant={'h2'} className={classes.subtitle}>
                {emailNotificationSettingBody4}
            </Typography>
            <Typography className={classes.emailNotificationText}>
                {emailNotificationSettingBody5}
            </Typography>
        </>
        }

        {!isEmailsNotificationsActive && <RestrictionsSectionMessageText/>}

        <Form onSubmit={updateEmailHandler}>
            {({formProps}: any) => (
                <form {...formProps}>
                    <Field
                        name="email"
                        label={emailNotificationSettingEmailLabel}
                        defaultValue={props.userEmail}
                    >
                        {({fieldProps, error}) => (
                            <div>
                                <Textfield {...fieldProps}
                                           placeholder="Enter your email here"
                                           onClick={() => {
                                               setEditingEmail(true)
                                               setEmailTouched(true)
                                           }}
                                           className={classes.emailTextfield}
                                           onChange={(event: any) => {
                                               fieldProps.onChange(event)
                                               validateEmailHandler(event.target.value)
                                           }}
                                           elemAfterInput={ isEmailsNotificationsActive ?
                                               <div style={{paddingRight: 6, lineHeight: '100%'}}>
                                                   <EditIcon label="edit"/>
                                               </div> : ''
                                           }
                                />
                                {!errors.email && (
                                    <HelperMessage>
                                        {editingEmail ? emailNotificationsEmailText : emailNotificationSettingHelpTextNewEmail}
                                    </HelperMessage>
                                )}
                                {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
                            </div>
                        )}
                    </Field>
                    <CheckboxField name="eula">
                        {({fieldProps}: any) => (
                            <div className={classes.eulaCheckbox}>
                                <Checkbox {...fieldProps}
                                          label={<div
                                              dangerouslySetInnerHTML={{__html: emailNotificationsEulaText}}></div>}
                                          onChange={(event) => {
                                              fieldProps.onChange(event)
                                              validateEULAHandler(event.target.checked)
                                          }}
                                />
                                {errors.eula &&
                                <div className={classes.eulaCheckboxValidationError}>
                                    <ErrorMessage>{errors.eula}</ErrorMessage>
                                </div>}
                            </div>
                        )}
                    </CheckboxField>

                    <FormFooter>
                        <Button type="submit" appearance={'primary'} isDisabled={!emailTouched}>
                            {emailNotificationSettingSubmitButton}
                        </Button>
                    </FormFooter>
                </form>
            )}
        </Form>
    </>
}

export default NotificationSettingsForm