import React from 'react'
import {LicenseDetailsContext, LicenseDetailsProvider} from 'trello-shared-resources/dist/index'
import ThreadedCommentsDisplay from './ThreadedCommentsDisplay'
import {Skeleton} from 'antd'

/**
 * The top level Report 'view'. This wraps the report functionality in the require licensing provider.
 */
const ThreadedComments = () => {

    return <LicenseDetailsProvider>
        <LicenseDetailsContext.Consumer>
            {(licenseDetails: any) => {
                if (licenseDetails.isLoading) return <Skeleton active={true}/>
                return <ThreadedCommentsDisplay licenseDetails={licenseDetails}/>
            }}
        </LicenseDetailsContext.Consumer>
    </LicenseDetailsProvider>
}

export default ThreadedComments