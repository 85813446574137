import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grid: {
            height: '30vh',
            '& button': {
                fontFamily: 'SF UI Text Bold',
                float: 'right'
            },
            '& div': {
                width: '100%'
            }
        },
        secondParagraph: {
            marginTop: -30,
            lineHeight: '24px',
            fontSize: 16,
            whiteSpace: 'pre-wrap',
            fontFamily: 'SF Pro Text Regular',
            letterSpacing: 0,
            color: '#172B4D',
        }
    })
)

export { useStyles }