import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        feedbackExplain: {
            fontFamily: 'SF Pro Text Semibold',
            fontSize: 14,
            lineHeight: '16px',
            color: '#172B4D',
            marginBottom: 16
        },
        cancelFeedbackButton: {
            marginRight: 5
        },
        feedbackForm: {
            '& > div:nth-child(-n+5)': {
                marginTop: 0
            },
            '& > div:nth-child(6)': {
                marginTop: 16,
                marginBottom: 8,
                fontFamily: 'SF Pro Text Semibold',
                color: '#6B778C'
            }
        },
        buttonsContainer: {
            marginTop: 24
        },
    })
)

export {useStyles}