import React, { useState } from 'react'
import { LicenseDetailsContext, LicenseDetailsProvider } from 'trello-shared-resources/dist/index'
import { useStyles } from './MiniOnboardingStyles'
import { Grid } from '@material-ui/core'
import { ThreadedCommentsEmailCheckoutSteps } from '../../../types/ThreadedCommentsEmailCheckoutSteps'
import MiniOnboardingCollect from './MiniOnboardingCollect'
import MiniOnboardingSuccess from './MiniOnboardingSuccess'

/**
 * Mini Onboarding. Shown for "no-admin" users and for "admin" users that skipped the email notification onboarding
 */
const MiniOnboarding = () => {
    const classes = useStyles()
    const [currentStep, setCurrentStep] = useState<ThreadedCommentsEmailCheckoutSteps>(ThreadedCommentsEmailCheckoutSteps.COLLECT)

    return <LicenseDetailsProvider>
        <LicenseDetailsContext.Consumer>
            {(licenseDetails: any) => {
                return (
                    <Grid container spacing={0} className={classes.grid}>
                        {currentStep === ThreadedCommentsEmailCheckoutSteps.COLLECT &&
                        <MiniOnboardingCollect licenseDetails={licenseDetails} setCurrentStep={setCurrentStep}
                                               currentStep={currentStep}/>
                        }
                        {currentStep === ThreadedCommentsEmailCheckoutSteps.SUCCESS &&
                        <MiniOnboardingSuccess licenseDetails={licenseDetails}/>
                        }
                    </Grid>
                )
            }}
        </LicenseDetailsContext.Consumer>
    </LicenseDetailsProvider>
}

export default MiniOnboarding