/**
 * Resize the iframe to set the total height also considering absolute divs
 */
export const resizeIframe = (trelloIframeContext: any) => {
    setTimeout(() => {
        sizeToContent(trelloIframeContext)
        setTimeout(() => {
            const iframeHeight = document.documentElement.scrollHeight
            document.getElementById('root')!.style.height = iframeHeight + 'px'
        }, 100)
    }, 200)
}

/**
 * Resize the content to the correct height only if element is present
 */
export const sizeToContent = (trelloIframeContext: any) => {
    const content = document.getElementById('content')
    if (content) {
        trelloIframeContext.sizeTo('#content')
    }
}

/**
 * Reset the iframe height to the visible content without absolute divs
 */
export const resetIframeSize = (trelloIframeContext: any) => {
    setTimeout(() => {
        document.getElementById('root')!.style.removeProperty('height')
        sizeToContent(trelloIframeContext)
    }, 200)
}

/**
 * Calculate if the emoji popup have to be displayed above
 * @param emojiRef the button where the user has clicked and where we will base to decide to render the popup
 * @return boolean true if it can't be render below
 */
export const showEmojiPickerAbove = (emojiRef: any) => {
    if (!emojiRef || !emojiRef.current) return false
    const emojiPickerHeight = 318
    const emojiIconVerticalPosition = emojiRef.current!.getBoundingClientRect().y
    const appHeight = document.documentElement.offsetHeight
    if (appHeight < 1500) return emojiIconVerticalPosition > emojiPickerHeight
    else return (emojiPickerHeight + emojiIconVerticalPosition) > appHeight
}

/**
 * Throw a general error boundary and resize the PU automatically
 * More details https://github.com/bvaughn/react-error-boundary
 * @param errorHandlerFunction error handler function from hook useErrorHandler() in the component
 * @param trelloIframeContext trello context Pu
 */
export const throwError = (errorHandlerFunction: any, trelloIframeContext: any) => {
    trelloIframeContext.sizeTo('#content')
    errorHandlerFunction.call()
}

