import {makeStyles} from '@material-ui/core/styles'
import {createStyles, Theme} from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        infoMessage: {
            color: '#172B4D',
            fontFamily: "SF Pro Text",
            fontSize: 14,
            letterSpacing: 0,
            lineHeight: '21px'
        },
    })
)

export {useStyles}