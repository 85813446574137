import {Grid, Typography} from '@material-ui/core'
import Checkbox from '@atlaskit/checkbox'
import TextArea from '@atlaskit/textarea'
import Button from '@atlaskit/button'
import React, {useState} from 'react'
import {useStyles} from './UnsubscribeFeedbackFormStyles'
import {UnsubscribeFeedbackFormProps} from '../../types/UnsubscribeFeedbackFormProps'
import {
    unsubscribeFeedbackErrorBody,
    unsubscribeFeedbackErrorGoBack,
    unsubscribeFeedbackErrorTitle,
    unsubscribeFeedbackExplain,
    unsubscribeFeedbackReason1,
    unsubscribeFeedbackReason2,
    unsubscribeFeedbackReason3,
    unsubscribeFeedbackReason4,
    unsubscribeFeedbackReason5,
    unsubscribeFeedbackSubmit,
    unsubscribeFeedbackTextAreaPlaceholder,
    unsubscribeGoToTrello,
    unsubscribeGoToTrelloBoard
} from '../emailNotifications/onboarding/email-notifications-messages'
import Form, {CheckboxField, Field} from '@atlaskit/form'
import {storeUnsubscribeFeedback} from '../../modules/Persistence'
import {UnsubscribeFeedbackData} from '../../types/UnsubscribeFeedbackData'
import Modal, {ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition} from '@atlaskit/modal-dialog'
import * as Sentry from '@sentry/browser'
import {TrackActionEvent} from 'trello-shared-resources/dist'

const UnsubscribeFeedbackForm = (props: UnsubscribeFeedbackFormProps) => {

    const classes = useStyles()

    const {boardId, emailSubscriptionId} = props

    const [openErrorModal, setOpenErrorModal] = useState(false)

    const closeModal = () => setOpenErrorModal(false)

    const onSubmitHandler = (form: any) => {
        const unsubscribeFormData: UnsubscribeFeedbackData = {
            dontWantNotifications: form.dontWantNotifications?.length > 0,
            tooManyEmails: form.tooManyEmails?.length > 0,
            dontRememberSigningUp: form.dontRememberSigningUp?.length > 0,
            noLongerUseTC: form.noLongerUseTC?.length > 0,
            other: form.other?.length > 0,
            reason: form.reason || ''
        }
        const trelloIframeContext = props.trelloIframeContext
        storeUnsubscribeFeedback(props.trelloIframeContext, unsubscribeFormData, props.emailSubscriptionId)
            .then(() => {
                TrackActionEvent('Store unsubscribe feedback', props.trelloIframeContext, {
                    member_id: trelloIframeContext.member
                })
                props.setSuccessFeedbackForm(true)
            })
            .catch((error) => {
                Sentry.captureException(error)
                setOpenErrorModal(true)
            })
    }

    return <>
        <Typography className={classes.feedbackExplain}>{unsubscribeFeedbackExplain}</Typography>
        <Form onSubmit={onSubmitHandler}>
            {({formProps}) => (
                <form {...formProps} className={classes.feedbackForm}>
                    <CheckboxField value={unsubscribeFeedbackReason1} name="dontWantNotifications">
                        {({fieldProps}) => <Checkbox {...fieldProps} label={fieldProps.value}/>}
                    </CheckboxField>

                    <CheckboxField value={unsubscribeFeedbackReason2} name="tooManyEmails">
                        {({fieldProps}) => <Checkbox {...fieldProps} label={fieldProps.value}/>}
                    </CheckboxField>

                    <CheckboxField value={unsubscribeFeedbackReason3} name="dontRememberSigningUp">
                        {({fieldProps}) => <Checkbox {...fieldProps} label={fieldProps.value}/>}
                    </CheckboxField>

                    <CheckboxField value={unsubscribeFeedbackReason4} name="noLongerUseTC">
                        {({fieldProps}) => <Checkbox {...fieldProps} label={fieldProps.value}/>}
                    </CheckboxField>

                    <CheckboxField value={unsubscribeFeedbackReason5} name="other">
                        {({fieldProps}) => <Checkbox {...fieldProps} label={fieldProps.value}/>}
                    </CheckboxField>

                    <Field<string, HTMLTextAreaElement>
                        name="reason"
                        label="Other reason and/or comment"
                    >
                        {({fieldProps}) => <TextArea {...fieldProps} resize="smart"
                                                     placeholder={unsubscribeFeedbackTextAreaPlaceholder}/>}
                    </Field>

                    <Grid container className={classes.buttonsContainer} justifyContent={'flex-end'}>
                        {emailSubscriptionId &&
                            <Button href={boardId ? `https://trello.com/b/${boardId}` : 'https://trello.com'}>
                                {boardId ? unsubscribeGoToTrelloBoard : unsubscribeGoToTrello}
                            </Button>
                        }
                        <Button appearance="primary" type="submit">{unsubscribeFeedbackSubmit}</Button>
                    </Grid>
                </form>
            )}
        </Form>
        <ModalTransition>
            {openErrorModal && (
                <Modal onClose={closeModal}>
                    <ModalHeader>
                        <ModalTitle>{unsubscribeFeedbackErrorTitle}</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <Typography>{unsubscribeFeedbackErrorBody}</Typography>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            autoFocus
                            appearance="danger"
                            onClick={closeModal}
                        >
                            {unsubscribeFeedbackErrorGoBack}
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </ModalTransition>

    </>
}

export default UnsubscribeFeedbackForm