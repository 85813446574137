import React from 'react'
import { Typography } from '@material-ui/core'
import { useStyles } from './CommonComponentsStyles'
import emailSignupImage from '../../../assets/images/threaded-comments-logo.svg'

/**
 * Common component: TC icon with a defined title
 */
const IconWithTitle = (props: { title: string }) => {
    const classes = useStyles()

    return (
        <>
            <img src={emailSignupImage} alt={classes.title} className={classes.appIcon}/>
            <Typography className={classes.title}>
                {props.title}
            </Typography>
        </>
    )
}

export default IconWithTitle