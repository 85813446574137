import InfoIcon from '@atlaskit/icon/glyph/info'
import {Grid, Link, Typography} from '@material-ui/core'
import React from 'react'
import {
    unsubscribeFeedbackSuccessBody1, unsubscribeFeedbackSuccessBody2, unsubscribeFeedbackSuccessLink
} from '../emailNotifications/onboarding/email-notifications-messages'
import {useStyles} from './UnsubscribeFeedbackSuccessStyles'

const UnsubscribeFeedbackSuccess = () => {

    const classes = useStyles()

    return <Grid container spacing={1}>
        <Grid item xs="auto">
            <InfoIcon label="info" primaryColor="#42526E" size="medium"/>
        </Grid>
        <Grid item xs>
            <Typography className={classes.infoMessage}>
                {unsubscribeFeedbackSuccessBody1}<Link href='https://trello.com'>{unsubscribeFeedbackSuccessLink}</Link>{unsubscribeFeedbackSuccessBody2}
            </Typography>
        </Grid>
    </Grid>
}

export default UnsubscribeFeedbackSuccess