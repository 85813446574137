import SectionMessage from '@atlaskit/section-message'
import {Box, Link} from '@material-ui/core'
import React from 'react'
import {useStyles} from './UnsubscribeSuccessMessegaStyle'
import {UnsubscribeResultMessageProps} from '../../types/UnsubscribeResultMessageProps'

const UnsubscribeResultMessage = (props: UnsubscribeResultMessageProps) => {

    const classes = useStyles()

    return <Box className={classes.resultContainer}>
        <SectionMessage
            title={props.title}
            appearance={props.appearance}
        >
            <p>
                {props.body}
                <Link href={props.link} target='_blank'>{props.linkText}</Link>.
            </p>
        </SectionMessage>
    </Box>
}

export default UnsubscribeResultMessage