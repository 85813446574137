import SectionMessage, {SectionMessageAction} from '@atlaskit/section-message'
import {Box} from '@material-ui/core'
import React from 'react'
import {useStyles} from './NotificationSettingsStyles'
import {NotificationUpdateErrorMessageProperties} from '../../../types/NotificationUpdateErrorMessageProperties'
import {supportUrl} from 'trello-shared-resources/dist'
import {
    emailNotificationSettingClose,
    emailNotificationSettingContactSupport,
} from '../../emailNotifications/onboarding/email-notifications-messages'

const NotificationUpdateErrorMessage = (props: NotificationUpdateErrorMessageProperties) => {

    const classes = useStyles()

    const goToSupport = () => {
        window.open(supportUrl, '_blank')
    }

    let actions
    if (props.closable) actions = [<SectionMessageAction onClick={() => props.setErrorUpdate(false)}>
        {emailNotificationSettingClose}
    </SectionMessageAction>,
        <SectionMessageAction onClick={goToSupport}>
            {emailNotificationSettingContactSupport}
        </SectionMessageAction>]
    else actions = [
        <SectionMessageAction onClick={goToSupport}>
            {emailNotificationSettingContactSupport}
        </SectionMessageAction>]

    return <Box className={classes.resultContainer}>
        <SectionMessage
            title={props.titleErrorMessage}
            appearance="error"
            actions={actions}
        >
            {props.children}
        </SectionMessage>
    </Box>
}

export default NotificationUpdateErrorMessage