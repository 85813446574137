import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        warningMessageContent: {
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: 24,
            paddingRight: 24,
            color: '#172B4D',
            fontFamily: 'SF Pro Text Regular',
            fontSize: 14,
            lineHeight: '20px'
        },
        warningLink: {
            color: '#0052CC'
        },
        emoji: {
            ['@media\n' +
            'screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm)']: {
                paddingRight: 4,
            },
            //chrome based and retina
            ['@media\n' +
            'screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) and (-webkit-min-device-pixel-ratio: 2),\n' +
            'screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) and (   min--moz-device-pixel-ratio: 2),\n' +
            'screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) and (     -o-min-device-pixel-ratio: 2/1),\n' +
            'screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) and (        min-device-pixel-ratio: 2),\n' +
            'screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) and (                min-resolution: 192dpi),\n' +
            'screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) and (                min-resolution: 2dppx))']: {
                paddingRight: 2,
            },
            //firefox
            ['@media\n' +
            'all and (min--moz-device-pixel-ratio:0)']: {
                paddingRight: 2,
            },
            // safari
            ['@media not all and (min-resolution:.001dpcm) { \n' +
            '@media' ]: {
                height: 22,
            }
        }
    }),
)

export { useStyles }
