import {OptionType} from '@atlaskit/select'
import Member from '../types/Member'

/**
 * Check if the comment contains a user mention from someone who is not subscribed to receive notifications
 * @param commentText the comment that the user is writing
 * @param organizationMembersOptions members that can be mentioned
 * @return true if it contains a user mention that is not subscribed to receive notifications
 */
export const commentContainsMentionedUserWithoutNotifications = (commentText: string, organizationMembersOptions: OptionType[]) : boolean =>
    organizationMembersOptions.some(organizationMember =>
        commentText.includes(`@${organizationMember.value} `) && !organizationMember.isSubscribed
    )

/**
 * Remove warning characters --> (⚠️) from the given comment
 * @param commentText the comment that the user is writing
 * @return the string without warning characters --> (⚠️)
 */
export const clearWarningsFromComment = (commentText: string) : string =>
    commentText.replaceAll(/((@(\w|\.|-)+?) \(⚠️\))/g, '$2')

/**
 * Get the line number given the text area
 * @param textArea an object that contains the comment text and the caret position
 * @return line number
 */
export const getLineNumber = (textArea: any) : number =>
    textArea.value.substr(0, textArea.selectionStart).split('\n').length

/**
 * Add the warning characters --> (⚠️) to the given comment in case there are some users who are not subscribed but they
 * doesn't have the warning characters yet
 * @param commentText the comment that the user is writing
 * @param organizationMembersOptions members that can be mentioned
 * @return the comment updated and the number of warnings added
 */
export const addNeededWarnings = (commentText: string, organizationMembers: Member[]) : [string, number] => {
    let warningsAdded = 0
    return [commentText.replace(/((@(\w|\.|-)+?) ([^@;]*))/gm, (match, ...args: any[]) => {
        const foundMember = organizationMembers.find(organizationMember => args[1] === `@${organizationMember.username?.startsWith('@') ? organizationMember.username.slice(1) : organizationMember.username}`)
        if (foundMember && !foundMember.isSubscribed && !args[3].startsWith('(⚠️) ')) {
            warningsAdded++
            return `${args[1]} (⚠️) ${args[3]}`
        }
        return match
    }), warningsAdded]
}

/**
 * Remove the warning characters --> (⚠️) to the given comment in case there are some users who are subscribed but they
 * have the warning characters
 * @param commentText the comment that the user is writing
 * @param organizationMembersOptions members that can be mentioned
 * @return the comment updated and the number of removed warnings
 */
export const removeUnneededWarnings = (commentText: string, organizationMembersOptions: OptionType[]) : [string, number] => {
    let warningsRemoved = 0
    return [commentText.replace(/((@(\w|\.|-)+?) \(⚠️\))/g, (match, ...args: any[]) => {
        const foundMember = organizationMembersOptions.find(organizationMembersOption => args[1] === `@${organizationMembersOption.value}`)
        if (foundMember && !foundMember.isSubscribed) return match
        warningsRemoved++
        return args[1]
    }), warningsRemoved]
}

/**
 * Remove the warning characters --> (⚠️) and the last username character if the user is trying to remove the warning characters
 * @param newComment the comment that the user has updated
 * @param oldComment the original comment
 * @param organizationMembersOptions members that can be mentioned on comments
 * @param selectionStart the caret position
 * @return the comment updated and the new caret position
 */
export const removeWarningAndLastUsernameCharacter = (newComment: string, oldComment: string, organizationMembersOptions: OptionType[], selectionStart: number): [string | null, number | null] => {
    const characterRemoved = getDifference(newComment.split(''), oldComment.split(''))
    let charactersToRemove = calculateCharactersToRemove(characterRemoved)
    const commentUntilCaret = newComment.substr(0, selectionStart)
    const prevTextIsMention = previousContentIsMention(commentUntilCaret, organizationMembersOptions, characterRemoved)
    const warningCharactersRemoved = ['(', '⚠️', '\u26A0', ')', ' ', ''].includes(characterRemoved || '')
    const lastUserMention = lastMention(commentUntilCaret, characterRemoved)
    const lastUserMentionIsSubscribed = organizationMembersOptions.find(organizationMember => lastUserMention === `@${organizationMember.value}`)?.isSubscribed
    const removingCharacters = newComment.length < oldComment.length
    let caretPositionAfterLastUserMention = selectionStart - (lastUserMention ? lastUserMention.length + 1 : 0)
    const newCommentSinceMention = newComment.substr(caretPositionAfterLastUserMention < 0 ? 0 : (caretPositionAfterLastUserMention + (newComment.startsWith('@') ? 0 : 1)))
    charactersToRemove = newCommentSinceMention.startsWith(`${lastUserMention}(⚠️) `) ? 1 : charactersToRemove
    if (removingCharacters) caretPositionAfterLastUserMention -= lastUserMentionIsSubscribed ? 1 : charactersToRemove
    const textSinceMention = oldComment.substr(caretPositionAfterLastUserMention < 0 ? 0 : caretPositionAfterLastUserMention)

    if (removingCharacters && characterRemoved && warningCharactersRemoved && prevTextIsMention && lastUserMention && textSinceMention.includes('(⚠️)')) {
        const leftSideComment = oldComment.substr(0, commentUntilCaret.length - charactersToRemove)
        const rightSideComment = oldComment.substr(selectionStart + (7 - charactersToRemove))
        const editedComment =  `${leftSideComment}${rightSideComment !== '' ? ' ': ''}${rightSideComment}`
        const newCaretPosition = selectionStart - charactersToRemove
        return [editedComment, newCaretPosition]
    }
    return [null, null]
}

/**
 * Calculate the new caret position based on:
 *
 *      the given original position + 5 chars * # of added warnings - 4 chars * # of removed warnings
 *
 * @param originalPosition position where the user is with its cursor on the textfield
 * @param warningsAdded number of added warnings
 * @param warningsRemoved number of removed warnings
 * @return the new caret position to keep the same position as the user was
 */
export const calculateNewCaretPosition = (originalPosition: number, warningsAdded: number, warningsRemoved: number) : number => {
  return originalPosition + warningsAdded * 5 - warningsRemoved * 4
}

/**
 * Calculate the number of characters to remove based on the character that has been removed by the user
 * @param removedCharacter the character that has been removed by the user
 * @return the number of characters to remove
 */
const calculateCharactersToRemove = (removedCharacter: string | undefined) : number => {
    switch (removedCharacter) {
        case ' ':
            return 6
        case ')':
            return 5
        case '\u26A0':
            return 3
        case '(':
            return 2
        default:
            return 0
    }
}

/**
 * Find the previous user mention
 * @param commentTextUntilCaret the comment that the user is writing until the caret
 * @param removedCharacter the character that has been removed by the user
 * @return the user mention, ie, @username
 */
const lastMention = (commentTextUntilCaret: string, characterRemoved: string | undefined): string => {
    const commentSplitBySpace = commentTextUntilCaret.split(' ')
    if(commentSplitBySpace.length === 1) return commentSplitBySpace[0]
    if(characterRemoved === ' ' && commentSplitBySpace[commentSplitBySpace.length - 1] !== '(⚠️)') return commentSplitBySpace[commentSplitBySpace.length - 1]
    return commentSplitBySpace[commentSplitBySpace.length - 2]
}

/**
 * Check if the immediately previous content of the comment is a mention, ie @some_username
 * @param commentTextUntilCaret the comment that the user is writing until the caret
 * @param organizationMembersOptions members that can be mentioned on comments
 * @param removedCharacter the character that has been removed by the user
 * @return true if the immediately previous content of the comment is a mention, ie @some_username, false otherwise
 */
const previousContentIsMention = (commentTextUntilCaret: string, organizationMembersOptions: OptionType[], characterRemoved: string | undefined) : boolean => {
    const mentionRegExp = new RegExp(/(@(\w|.|-)+)/gi)
    const mention = lastMention(commentTextUntilCaret, characterRemoved)
    if (mention === '') return false
    return mentionRegExp.test(mention) && organizationMembersOptions.some(organizationMember =>
        mention === `@${organizationMember.value}`
    )
}

/**
 * Calculate the first different char between given strings
 * @param string1 string to compare
 * @param string2 string to compare
 * @return the first character or undefined if it's the same
 */
const getDifference = (string1: Array<string>, string2: Array<string>) : string | undefined => {
    string1 = [...string1].sort()
    string2 = [...string2].sort()
    return string2.find((char, index) => char !== string1[index])
}

/**
 * Remove "TC_SELF_MENTION_TC" or "TC_MENTION_TC" from the given content
 * @param content string or array of string to remove the helper
 * @return the cleared string
 */
export const clearMentionHelper = (content: string | Array<string>) => {
    if (!content || !content.length) return ''
    if (Array.isArray( content)) {
        return content.map(partialContent => partialContent.replace(/\*\*TC_SELF_MENTION_TC|TC_SELF_MENTION_TC\*\*|\*\*TC_MENTION_TC|TC_MENTION_TC\*\*/g, ''))
    }
    return content.replace(/\*\*TC_SELF_MENTION_TC|TC_SELF_MENTION_TC\*\*|\*\*TC_MENTION_TC|TC_MENTION_TC\*\*/g, '')
}