import { TrackActionEvent } from 'trello-shared-resources/dist'

/**
 * Track all events related to Email Notification
 *
 * @param trelloContextInfo
 * @param actionType
 */
export const trackEmailNotificationEvent = (trelloContextInfo: any, actionType: EmailNotificationEventType) => {
    TrackActionEvent('Email Notification', trelloContextInfo, {
        board_id: trelloContextInfo.board,
        member_id: trelloContextInfo.member,
        action: actionType,
    })
}

/**
 * Email Notification Event Types
 * A user can add, edit and remove its email from the database
 * We also track when a user wants to start the subscription (e.g. during the mini-onboarding) or when the user rejects it
 */
export enum EmailNotificationEventType {
    ADD = 'add',
    EDIT = 'edit',
    REMOVE = 'remove',
    START = 'start',
    REJECT = 'reject',
}

export const settingsClickHandler = (action: string, trelloIframeContext: any) => {
    const trelloContextInfo = trelloIframeContext.getContext()
    TrackActionEvent('Settings link', trelloContextInfo, {
        board_id: trelloContextInfo.board,
        member_id: trelloContextInfo.member,
        action: action
    })
    trelloIframeContext.modal({
        url: './index.html?apptype=settings',
        fullscreen: true,
        resizable: false,
        title: `${process.env.REACT_APP_TRELLO_APP_NAME} Settings`,
        accentColor: '#EBEDF0'
    })
}