import React from 'react'
import { Link } from '@material-ui/core'
import { useStyles } from './CommonComponentsStyles'
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message'
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle'
import {
    emailNotificationsFaqLink,
    emailNotificationsSectionMessage,
    emailNotificationsSectionMessageAction
} from '../onboarding/email-notifications-messages'
import RestrictionsSectionMessageText from './RestrictionsSectionMessageText'

/**
 * Common component: Section Message to explain Trello restrictions
 */
const RestrictionsSectionMessage = () => {
    const classes = useStyles()


    const CustomLink = React.forwardRef(
        /* istanbul ignore next */
        (props = {}, ref: React.Ref<HTMLButtonElement>) => (
            <Link ref={ref} {...props} target="_blank"/>
        )
    )

    return (
        <>
            <div className={classes.sectionMessage}>
                <SectionMessage
                    icon={QuestionCircleIcon}
                    actions={[
                        <SectionMessageAction linkComponent={CustomLink}
                                              href={emailNotificationsFaqLink}>
                            {emailNotificationsSectionMessageAction}
                        </SectionMessageAction>
                    ]}
                >{emailNotificationsSectionMessage}
                </SectionMessage>
            </div>
            <RestrictionsSectionMessageText/>
        </>
    )
}

export default RestrictionsSectionMessage