import React from 'react'
import Button from '@atlaskit/button'
import Tooltip from '@atlaskit/tooltip'
import { Grid } from '@material-ui/core'
import { useStyles } from './SettingsMenuButtonStyles'
import { settingsClickHandler } from '../../emailNotifications/onboarding/EmailNotificationsUtils'

/**
 * Settings Menu Button
 */
const SettingsMenuButton = (props: { licenseDetails: any }) => {
    const classes = useStyles()

    return (
        <Grid container justifyContent="flex-end" className={classes.settingsButton}>
            <Tooltip content="Click to open Settings">
                {(tooltipProps) => (
                    <Button {...tooltipProps}
                            onClick={() => settingsClickHandler('settings_button_click', props.licenseDetails.trelloIframeContext)}>
                        Settings
                    </Button>
                )}
            </Tooltip>
        </Grid>
    )
}

export default SettingsMenuButton