import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
    resultContainer: {
        marginTop: 25,
        marginBottom: 36,
        '& ul': {
            margin: 0
        }
    },
})

export {useStyles}
