import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        floatPickerContainer: {
            position: 'absolute',
            right: '12px',
            zIndex: 999,
        },
        floatPickerContainerRoot: {
            top: '42px'
        },
        floatPickerContainerThread: {
            bottom: '42px'
        },
        commentEditorContainer: {
            position: 'relative',
            display: 'inline-block',
            width: '100%',
            marginBottom: 16,
            '& textarea': {
                height: 34,
                fontFamily: 'SF Pro Text Regular'
            }
        },
        commentEditorRepliesContainer: {
            width: '100%',
            marginBottom: 4,
        },
        commentEditorEmpty: {
            '& textarea': {
                height: '34px !important'
            }
        },
        emojiLink: {
            position: 'absolute',
            top: 0,
            right: 0,
        },
        mentionContainer: {
            position: 'absolute',
            left: 5,
            width: 200
        },
        saveContainer: {
            marginRight: 1,
            martingTop: 1,
            marginBottom: 16,
            marginLeft: 'auto'
        },
        saveReplyContainer: {
            martingTop: 1,
            marginLeft: 'auto'
        },
        warningIcon: {
            fontFamily: 'SF Pro Text Regular'
        },
        warningExplain: {
            fontFamily: 'SF Pro Text Regular',
            fontSize: 12,
            lineHeight: '18px',
            color: '#253858'
        },
        notificationsSignUpLink: {
            color: '#0052CC'
        },
        warningIconContainer: {
            marginLeft: 3,
            marginRight: 7,
            marginTop: -2
        },
        warningContainer: {
            marginTop: 0,
            marginBottom: 6
        }
    }),
)

export { useStyles }
