import Member from '../types/Member'
import CommentStored from '../types/CommentStored'
import CommentData from '../types/CommentData'
import {CommentReactionStored} from '../types/CommentReactionStored'
import {CommentReactionGrouped} from '../types/CommentReactionGrouped'
import {CommentReaction} from '../types/CommentReaction'

/**
 * Process info from backend and group threaded comments
 * @param licenseDetails The context that we will use to retrieve data.
 * @param commentsStored all comments obtained from backend
 * @param searchAndStoreMember method to update the board members
 */
export async function processComments(licenseDetails: any, commentsStored: Array<CommentStored>, searchAndStoreMember: (memberId: string) => Promise<Member | undefined>): Promise<CommentData[]> {
    const comments: Array<CommentData> = []
    const rootComments = commentsStored.filter(comment => !comment.parentCommentId)
    for (const rootComment of rootComments) {
        const comment = await processComment(licenseDetails, rootComment, searchAndStoreMember)
        await collectThreadedComments(licenseDetails, comment, commentsStored, searchAndStoreMember)
        comments.push(comment)
    }

    await collectOrphanComments(licenseDetails, commentsStored, searchAndStoreMember, comments)
    return sortCommentsByDateDesc(comments)
}

/**
 * Sort comments by creation date desc
 * @param comments comment array to order
 */
function sortCommentsByDateDesc(comments: Array<CommentData>) {
    return comments.sort((comment1: CommentData, comment2: CommentData) =>
        comment1.creationDate < comment2.creationDate ? 1 : -1
    )
}

/**
 * Group an array of objects by the given key
 */
const groupByKey = (list: Array<any>, key: string) =>
    list.reduce((hash, obj) => ({...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj)}), {})

/**
 * Find orphan comments and process them
 * @param licenseDetails The context that we will use to retrieve data.
 * @param commentsStored all comments obtained from backend
 * @param searchAndStoreMember method to search a member on the current state or find it through the Trello API
 * @param comments comments proccessed
 */
async function collectOrphanComments(licenseDetails: any, commentsStored: Array<CommentStored>, searchAndStoreMember: (memberId: string) => Promise<Member | undefined>, comments: Array<CommentData>): Promise<void> {
    const rootCommentsIds = commentsStored
        .filter(comment => !comment.parentCommentId)
        .map(comment => comment.hashKey)
    const orphanComments = commentsStored
        .filter(comment => comment.parentCommentId && !rootCommentsIds.includes(comment.parentCommentId))

    if (orphanComments && orphanComments.length > 0) {
        const groupedOrphanComments = groupByKey(orphanComments, 'parentCommentId')
        for (const parentCommentId of Object.keys(groupedOrphanComments)) {
            const groupedOrphanComment = groupedOrphanComments[parentCommentId]
            const rootCommentDeleted: CommentData = {
                commentContent: 'This comment was deleted',
                commentDisplayContent: 'This comment was deleted',
                cardId: '',
                creationDate: new Date()
            }
            rootCommentDeleted.idRemovedComment = groupedOrphanComment[0].parentCommentId
            const orphanCommentsProccessed = []
            for (const orphanComment of groupedOrphanComment) {
                const comment = await processComment(licenseDetails, orphanComment, searchAndStoreMember)
                orphanCommentsProccessed.push(comment)
            }
            rootCommentDeleted.threadComments = orphanCommentsProccessed
            rootCommentDeleted.creationDate = orphanCommentsProccessed[0].creationDate
            comments.push(rootCommentDeleted)
        }
    }
}

/**
 * Fill a root comment that may contain threaded comments
 * @param licenseDetails The context that we will use to retrieve data.
 * @param rootComment parent comment that contains
 * @param commentsStored all comments obtained from backend
 * @param searchAndStoreMember method to search a member on the current state or find it through the Trello API
 */
async function collectThreadedComments(licenseDetails: any, rootComment: CommentData, commentsStored: Array<CommentStored>, searchAndStoreMember: (memberId: string) => Promise<Member | undefined>) {
    const threadedProccessedComments = []
    const threadedComments = commentsStored.filter(comment => comment.parentCommentId === rootComment.id)
    for (const threadedComment of threadedComments) {
        const comment = await processComment(licenseDetails, threadedComment, searchAndStoreMember)
        threadedProccessedComments.push(comment)
    }
    rootComment.threadComments = threadedProccessedComments
}

/**
 * Fill and format a comment with the given data
 * @param licenseDetails The context that we will use to retrieve data.
 * @param commentStored the object obtained from the backend with some brute data
 * @param searchAndStoreMember method to search a member on the current state or find it through the Trello API
 */
async function processComment(licenseDetails: any, commentStored: CommentStored, searchAndStoreMember: (memberId: string) => Promise<Member | undefined>): Promise<CommentData> {
    const member = await searchAndStoreMember(commentStored.memberId)
    const currentMember = await searchAndStoreMember(licenseDetails.trelloIframeContext.getContext().member)

    const mentionedMembers: Member[] = commentStored.mentionedMembers ? (await Promise.all(commentStored.mentionedMembers.map(mentionedMemberId =>
            searchAndStoreMember(mentionedMemberId))))
            .filter(member => member !== undefined) as Member[]
        : []

    const comment: CommentData = {
        id: commentStored.hashKey,
        cardId: commentStored.cardId,
        commentContent: commentStored.comment,
        commentDisplayContent: convertComment(commentStored.comment, mentionedMembers, currentMember!),
        memberId: commentStored.memberId,
        member: member,
        creationDate: new Date(commentStored.creationDate),
        mentionedMembers: mentionedMembers,
        parentCommentId: commentStored.parentCommentId
    }
    await processReactions(licenseDetails, comment, commentStored.reactions, searchAndStoreMember)
    return comment
}

/**
 * Group reactions and search their creator user names
 * @param licenseDetails The context that we will use to retrieve data.
 * @param comment comment
 * @param reactionsStored the object obtained from the backend with some brute data
 * @param searchAndStoreMember method to update the board members
 */
export async function processReactions(licenseDetails: any, comment: CommentData, reactionsStored: Array<CommentReactionStored> | undefined, searchAndStoreMember: (memberId: string) => Promise<Member | undefined>) {
    if (reactionsStored) {
        comment.reactions = reactionsStored
        const currentMemberId = licenseDetails.trelloIframeContext.getContext().member
        const groupedCommentReactions = groupByKey(reactionsStored, 'emoji')
        const groupedCommentReactionsList: Array<CommentReactionGrouped> = []
        for (const emoji of Object.keys(groupedCommentReactions)) {
            const groupedCommentReaction = groupedCommentReactions[emoji].sort((commentReaction: CommentReaction, commentReaction2: CommentReaction) =>
                commentReaction.creationDate < commentReaction2.creationDate ? -1 : 1)

            const commentReactionMembers: Member[] = []
            for (const commentReaction of groupedCommentReaction) {
                const member: Member | undefined = await searchAndStoreMember(commentReaction.memberId)
                if (member) commentReactionMembers.push(member)
            }

            const memberNames = commentReactionMembers
                .map(member => (member.id !== currentMemberId ? member.fullName : 'you') || '')
                .filter(memberName => memberName !== '')
                .sort((memberName1: string, memberName2: string) => memberName1 > memberName2 ? 1 : -1)
                .join(', ')
                .replace(/, ([^,]*)$/, ' and $1')

            groupedCommentReactionsList.push({
                emoji: emoji,
                emojiName: groupedCommentReaction[0].emojiName,
                count: groupedCommentReaction.length,
                members: memberNames,
                firstCreationDate: new Date(parseInt(groupedCommentReaction[0].creationDate))
            })
        }
        groupedCommentReactionsList.sort((commentReaction: CommentReactionGrouped, commentReaction2: CommentReactionGrouped) =>
            commentReaction.firstCreationDate < commentReaction2.firstCreationDate ? -1 : 1)
        comment.groupedReactions = groupedCommentReactionsList
    }
}

/**
 * Format comment to add bold font to member mentions
 * @param comment the string to format
 * @param mentionedMembers all members objects stored on DB
 * @param currentMember the member is using the power up
 * @return the string with the usernames in bold font
 */
function convertMentions(comment: string, mentionedMembers: Member[], currentMember: Member) {
    const mentionExpression = /(@(\w|.|-)+? )/gi

    if (comment.match(new RegExp(mentionExpression))) {
        return comment.replaceAll(mentionExpression, (memberMatch: string) => {
            if (memberMatch === `@${currentMember?.username} `) {
                return `**TC_SELF_MENTION_TC${memberMatch.slice(0, -1)}TC_SELF_MENTION_TC** `
            }
            const member = mentionedMembers.find(mentionedMember => memberMatch === `@${mentionedMember.username} `)
            if (member) {
                return `**TC_MENTION_TC${memberMatch.slice(0, -1)}TC_MENTION_TC** `
            }
            return memberMatch
        })
    } else return comment
}

/**
 * Format comment content converting:
 *   - Mentions
 *   - URLs & emails
 * @param comment the string to format
 * @param mentionedMembers mentioned members
 * @param currentMember currentMember
 * @return the string with the new format
 */
export function convertComment(comment: string, mentionedMembers: Member[], currentMember: Member) {
    const convertedComment = convertMentions(comment, mentionedMembers, currentMember)
    return convertUrls(convertedComment)
}

/**
 * Format comment to include links for URLS
 * @param comment the string to format
 * @return the string with links for HTML rendering
 */
function convertUrls(comment: string) {
    // eslint-disable-next-line
    const emailExpression = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
    // eslint-disable-next-line
    const emailOrUrlExpression = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])|((https?:\/\/(?:www\.|(?!www)))?[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s|^"|^\']{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s|^"|^\']{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s|^"|^\']{2,}|www\.[a-zA-Z0-9]+\.[^\s|^"|^\']{2,})/gi

    if (comment.match(emailOrUrlExpression)) {
        let match, offset = 0
        const originalComment = comment
        while ((match = emailOrUrlExpression.exec(originalComment)) != null) {
            const matchedText = match[0]

            const isMarkdownUrl = originalComment.slice(match.index - 2, match.index) === ']('
            if (matchedText.match(emailExpression)) {
                comment = comment.slice(0, match.index + offset) + comment.slice(match.index + offset + matchedText.length, comment.length)
                comment = comment.slice(0, match.index + offset) + `[${matchedText}](mailto:${matchedText})` + comment.slice(match.index + offset)
                offset += matchedText.length + 11
            } else if (!isMarkdownUrl) {
                comment = comment.slice(0, match.index + offset) + comment.slice(match.index + offset + matchedText.length, comment.length)
                comment = comment.slice(0, match.index + offset) + `[${matchedText}](${matchedText.startsWith('http') ? '' : 'http://'}${matchedText})` + comment.slice(match.index + offset)
                offset += matchedText.length + (matchedText.startsWith('http') ? 4 : 11)
            }
        }
    }
    return comment
}