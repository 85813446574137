import {makeStyles} from '@material-ui/core/styles'
import {createStyles} from '@material-ui/core'

export const useStyles = makeStyles(() =>
    createStyles({
        container: {
            minHeight: '100vh',
            '& button': {
                fontFamily: 'SF UI Text Bold',
                float: 'right'
            }
        },
        title: {
            fontSize: 24,
            fontFamily: 'SF UI Text Regular',
            color: '#172B4E',
            letterSpacing: 0,
            lineHeight: '29px'
        },
        subtitle: {
            marginTop: 48,
            marginBottom: 24,
            fontFamily: 'SF Pro Text Semibold'
        },
        content: {
            marginTop: 16,
            marginBottom: 36,
            '& p': {
                lineHeight: '24px',
                fontSize: 16,
                whiteSpace: 'pre-wrap',
                fontFamily: 'SF Pro Text Regular',
                letterSpacing: 0,
                color: '#172B4D',
            }
        },
        secondParagraph: {
            marginTop: 24
        }
    })
)