import React from 'react'
import {
    emailNotificationSettingBody2,
    emailNotificationSettingBody2dot5,
    emailNotificationSettingBody3,
    emailNotificationSettingUnsubscribeButton
} from '../../emailNotifications/onboarding/email-notifications-messages'
import Button from '@atlaskit/button'
import {Typography} from '@material-ui/core'
import {useStyles} from './NotificationSettingsStyles'
import InfoIcon from '@atlaskit/icon/glyph/info'
import {deleteUserEmailForNotifications} from '../../../modules/Persistence'
import * as Sentry from '@sentry/browser'
import {
    EmailNotificationEventType,
    trackEmailNotificationEvent
} from '../../emailNotifications/onboarding/EmailNotificationsUtils'
import {NotificationSettingsFormProperties} from '../../../types/NotificationSettingsFormProperties'

const NotificationUnsubscribeSection = (props: NotificationSettingsFormProperties) => {

    const classes = useStyles()
    
    const unsubscribeHandler = () => {
        const trelloIframeContext = props.trelloIframeContext
        deleteUserEmailForNotifications(trelloIframeContext, props.emailSubscriptionId)
            .then(result => {
                trackEmailNotificationEvent(trelloIframeContext.getContext(), EmailNotificationEventType.REMOVE)
                props.setUserEmail('')
                props.setSuccessUpdate(true)
                props.setSuccessFeedbackForm(false)
            })
            .catch(error => {
                Sentry.captureException(error)
                props.setErrorUpdate(true)
            })
    }

    return (
        <>
            <Typography className={[classes.emailNotificationText, classes.emailNotificationUnsubscribeText].join(' ')}>
                {emailNotificationSettingBody2}
            </Typography>
            <Typography className={classes.emailNotificationText}>{emailNotificationSettingBody2dot5}</Typography>
            <Typography className={[classes.emailNotificationText, classes.emailNotificationDelay].join(' ')}>
                <InfoIcon label={emailNotificationSettingBody3} primaryColor="#42526E"/>
                {emailNotificationSettingBody3}
            </Typography>
            <Button appearance="link" onClick={unsubscribeHandler}>
                {emailNotificationSettingUnsubscribeButton}
            </Button>
        </>
    )
}

export default NotificationUnsubscribeSection