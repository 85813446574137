import React from 'react'
import Comment from './comment/Comment'
import {CommentListProps} from '../types/CommentListProps'

const CommentList = (props: CommentListProps) => {

    if (props.comments.length > 0) {
        return <>
            {props.comments.map((comment, index) =>
                <Comment {...props} comment={comment} key={comment.id || index}/>
            )}
        </>
    } else {
        return <></>
    }
}

export default CommentList