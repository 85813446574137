import React, {useState} from 'react'
import {Box, Checkbox, FormControlLabel, Grid, Typography} from '@material-ui/core'
import {useStyles} from './CommonComponentsStyles'
import Form, {CheckboxField, ErrorMessage, Field, FormFooter} from '@atlaskit/form'
import TextField from '@atlaskit/textfield'
import {
    emailNotificationsEmailText,
    emailNotificationSettingInvalidEmail,
    emailNotificationSettingMandatoryEmail,
    emailNotificationsEulaText
} from '../onboarding/email-notifications-messages'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Button, {ButtonGroup} from '@atlaskit/button'
import {emailValidation} from 'trello-shared-resources/dist/modules/utility/Validations'
import {storeUserEmailForNotifications} from '../../../modules/Persistence'
import {ThreadedCommentsEmailCheckoutSteps} from '../../../types/ThreadedCommentsEmailCheckoutSteps'
import {EmailNotificationEventType, trackEmailNotificationEvent} from '../onboarding/EmailNotificationsUtils'
import {setUserDismissMiniOnboarding} from '../../../modules/TrelloPersistence'
import {EmailAddressProperties} from '../../../types/EmailAddressProperties'
import {useErrorHandler} from 'react-error-boundary'

/**
 * Common component: Form to collect user email address
 */
const EmailAddressForm = (props: EmailAddressProperties) => {
    const classes = useStyles()
    const [emailValue, setEmailValue] = useState('')
    const [eulaValue, setEulaValue] = useState(false)
    const [errors, setErrors] = useState({})
    const handleError = useErrorHandler()


    const submitHandler = async () => {
        if (!eulaValue || emailValidation(emailValue) !== undefined) {
            return
        }

        const trelloIframeContext = props.licenseDetails.trelloIframeContext
        await storeUserEmailForNotifications(trelloIframeContext, emailValue)

        props.setCurrentStep(ThreadedCommentsEmailCheckoutSteps.SUCCESS)
        trackEmailNotificationEvent(trelloIframeContext.getContext(), EmailNotificationEventType.ADD)
        setUserDismissMiniOnboarding(props.licenseDetails)
    }

    const notTodayHandler = () => {
        try {
            props.setCurrentStep(ThreadedCommentsEmailCheckoutSteps.DISMISS)
            trackEmailNotificationEvent(props.licenseDetails.trelloIframeContext.getContext(), EmailNotificationEventType.REJECT)
            setUserDismissMiniOnboarding(props.licenseDetails)
            if (props.closeModalOnDismiss) {
                props.licenseDetails.trelloIframeContext.closeModal()
            }
        } catch (error: any) {
            handleError(error)
        }
    }

    const validateOnSubmit = () => {
        let errors = {}
        if (!eulaValue) {
            errors = {
                'eula-checkbox': `You must agree to enable email notifications`
            }
        }

        if (!emailValue) {
            errors = {
                ...errors,
                'email-field': emailNotificationSettingMandatoryEmail
            }
        } else if (emailValidation(emailValue) !== undefined) {
            errors = {
                ...errors,
                'email-field': emailNotificationSettingInvalidEmail
            }
        }
        setErrors(errors)
        return errors
    }

    return (
        <Form
            onSubmit={validateOnSubmit}
        >
            {({formProps}) => (
                <form {...formProps} className={classes.form}>
                    <Field name="email-field" label="Email Address" defaultValue="">
                        {({fieldProps, error}) => (
                            <Box>
                                <TextField
                                    {...fieldProps}
                                    width="440"
                                    onChange={(e) => {
                                        fieldProps.onChange(e)
                                        // @ts-ignore
                                        setEmailValue(e.target.value)
                                    }}
                                />
                                <Typography className={classes.emailText}>
                                    {emailNotificationsEmailText}
                                </Typography>
                                {errors.hasOwnProperty('email-field') &&
                                <ErrorMessage>{error}</ErrorMessage>}
                            </Box>
                        )}
                    </Field>
                    <CheckboxField name="eula-checkbox">
                        {({fieldProps, error}) => (
                            <Box mt={3}>
                                <Grid container spacing={0} direction="row" alignItems="center">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                                                checkedIcon={<CheckBoxIcon fontSize="small"/>}
                                                checked={eulaValue}
                                                onChange={() => setEulaValue(!eulaValue)}
                                                color="primary"
                                            />
                                        }
                                        label={
                                            <Typography className={classes.eulaText}
                                                        dangerouslySetInnerHTML={{__html: emailNotificationsEulaText}}>
                                            </Typography>
                                        }
                                    />
                                </Grid>
                                {errors.hasOwnProperty('eula-checkbox') &&
                                <ErrorMessage>{error}</ErrorMessage>}
                            </Box>
                        )}
                    </CheckboxField>
                    <FormFooter>
                        <ButtonGroup>
                            <Button onClick={notTodayHandler}>{props.cancelButtonText}</Button>
                            <Button appearance="primary" type="submit"
                                    onClick={() => submitHandler().catch((error: any) => handleError(error))}>{props.submitButtonText}</Button>
                        </ButtonGroup>
                    </FormFooter>
                </form>
            )}
        </Form>
    )
}

export default EmailAddressForm