import React from 'react'
import { LicenseDetailsContext, LicenseDetailsProvider } from 'trello-shared-resources/dist/index'
import EmailNotificationsCheckout from './EmailNotificationsCheckout'

/**
 * The top level for the onboarding part related to gather user email for notifications
 */
const EmailNotificationsCheckoutFlow = () => {

    return <LicenseDetailsProvider>
        <LicenseDetailsContext.Consumer>
            {(licenseDetails: any) => {
                return <EmailNotificationsCheckout licenseDetails={licenseDetails} />
            }}
        </LicenseDetailsContext.Consumer>
    </LicenseDetailsProvider>
}

export default EmailNotificationsCheckoutFlow