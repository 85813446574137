import React from 'react'
import { Box, Link, Typography } from '@material-ui/core'
import { useStyles } from './CommonComponentsStyles'
import {
    emailNotificationsStoredBody1,
    emailNotificationsStoredBody2,
    emailNotificationsStoredBody3,
    emailNotificationsStoredBody4
} from '../onboarding/email-notifications-messages'
import { settingsClickHandler } from '../onboarding/EmailNotificationsUtils'
import OnboardingProperties from 'trello-shared-resources/dist/types/OnboardingProperties'

/**
 * Common component: Success Message shown when the user subscribe to email notifications
 */
const SuccessMessage = (props: OnboardingProperties) => {
    const classes = useStyles()

    return (
        <Box className={classes.successMessageContent}>
            <Typography>{emailNotificationsStoredBody1}</Typography>
            <Typography>
                {emailNotificationsStoredBody2}
                <Link
                    onClick={() => settingsClickHandler('email_success', props.licenseDetails.trelloIframeContext)}>{emailNotificationsStoredBody3}</Link>
                {emailNotificationsStoredBody4}
            </Typography>
        </Box>
    )
}

export default SuccessMessage