import React from 'react'
import {Grid} from '@material-ui/core'
import {useStyles} from './EmailNotificationsStepStyles'
import emailSignupImage from '../../../../assets/images/happy-bunch-3.svg'
import EmailNotificationsText from '../emailNotificationsText/EmailNotificationsText'
import {OnboardingImage} from 'trello-shared-resources/dist/components'
import {ThreadedCommentOnboardingProperties} from '../../../../types/ThreadedCommentOnboardingProperties'
import {ThreadedCommentsEmailCheckoutSteps} from '../../../../types/ThreadedCommentsEmailCheckoutSteps'
import EmailNotificationsSuccess from '../emailNotificationsSuccess/EmailNotificationsSuccess'
import {EmailNotificationsDismiss} from '../emailNotificationsDismiss/EmailNotificationsDimisss'
import {TrackActionEvent} from 'trello-shared-resources/dist'

export const EmailNotificationsStep = (props: ThreadedCommentOnboardingProperties) => {
    const classes = useStyles()

    const trelloIframeContext = props.licenseDetails.trelloIframeContext
    const trelloContextInfo = trelloIframeContext.getContext()

    const takeMeToMyBoardClickHandler = (action: string) => {
        const boardId = trelloContextInfo.board
        const cardId = trelloContextInfo.card
        if (cardId) {
            trelloIframeContext.navigate({
                url: `https://trello.com/b/${boardId}/${cardId}`
            })
        } else {
            trelloIframeContext.navigate({
                url: `https://trello.com/b/${boardId}`
            })
        }
        TrackActionEvent('Take me to board', trelloContextInfo, {
            board_id: trelloContextInfo.board,
            member_id: trelloContextInfo.member,
            action: action
        })
        trelloIframeContext.closeModal()
    }

    return (
        <Grid container spacing={0} className={classes.grid}>
            <OnboardingImage image={emailSignupImage} backgroundColor="#E2FFE4" width="450px"/>
            {props.currentStep === ThreadedCommentsEmailCheckoutSteps.COLLECT && <EmailNotificationsText {...props}/>}
            {props.currentStep === ThreadedCommentsEmailCheckoutSteps.SUCCESS && <EmailNotificationsSuccess {...props} takeMeToMyBoardClickHandler={takeMeToMyBoardClickHandler} />}
            {props.currentStep === ThreadedCommentsEmailCheckoutSteps.DISMISS && <EmailNotificationsDismiss {...props} takeMeToMyBoardClickHandler={takeMeToMyBoardClickHandler} />}
        </Grid>
    )
}

export default EmailNotificationsStep
