import React from 'react'
import { Grid } from '@material-ui/core'
import { ThreadedCommentOnboardingProperties } from '../../../types/ThreadedCommentOnboardingProperties'
import IconWithTitle from '../commonComponents/IconWithTitle'
import { emailNotificationsTitle } from '../onboarding/email-notifications-messages'
import RestrictionsSectionMessage from '../commonComponents/RestrictionsSectionMessage'
import EmailAddressForm from '../commonComponents/EmailAddressForm'

export const MiniOnboardingCollect = (props: ThreadedCommentOnboardingProperties) => {

    return (
        <Grid container spacing={0}>
            <IconWithTitle title={emailNotificationsTitle}/>
            <RestrictionsSectionMessage/>
            <EmailAddressForm {...props} cancelButtonText="Cancel" submitButtonText="Sign Up" closeModalOnDismiss={true}/>
        </Grid>
    )
}

export default MiniOnboardingCollect