import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            appIcon: {
                height: 38,
                width: 38,
                borderRadius: 20,
                backgroundColor: '#00C0C2',
                marginRight: 12
            },
            title: {
                marginTop: 6,
                fontSize: 18,
                fontFamily: 'SF UI Text Bold',
                color: '#172B4D',
                letterSpacing: 0,
                lineHeight: '29px'
            },
            content: {
                marginTop: '24px',
                marginBottom: '24px',
                fontSize: 16,
                whiteSpace: 'pre-wrap',
                fontFamily: 'SF Pro Text Regular',
                letterSpacing: 0,
                color: '#172B4D',
                lineHeight: '24px'
            },
            successMessageContent: {
                marginTop: 16,
                marginBottom: 36,
                '& p:nth-child(1)': {
                    fontFamily: 'SF Pro Text Bold',
                },
                '& p': {
                    lineHeight: '24px',
                    fontSize: 16,
                    whiteSpace: 'pre-wrap',
                    fontFamily: 'SF Pro Text Regular',
                    letterSpacing: 0,
                    color: '#172B4D',
                }
            },
            sectionMessage: {
                marginTop: '24px',
                lineHeight: '24px',
                letterSpacing: 0,
                fontSize: 16,
                fontFamily: 'SF UI Text Regular',
            },
            infoMessage: {
                fontSize: 14,
                whiteSpace: 'pre-wrap',
                fontFamily: 'SF Pro Text Regular',
                letterSpacing: 0,
                color: '#172B4D',
                marginBottom: '24px',
                lineHeight: '24px'
            },
            emailText: {
                color: '#6B778C',
                fontFamily: 'SF UI Text Regular',
                fontSize: 11,
                letterSpacing: 0,
                marginTop: '4px',
                lineHeight: '16px'
            },
            eulaText: {
                color: '#253858',
                fontFamily: 'SF Pro Text Regular',
                fontSize: 14,
                letterSpacing: 0,
                lineHeight: '20px'
            },
            form: {
                width: '100%'
            },
        }
    )
)

export { useStyles }


