import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from '@material-ui/core'
import React, {useEffect, useRef} from 'react'
import {useStyles} from './RepliesStyle'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CommentEditor from '../commentEditor/CommentEditor'
import Comment from '../comment/Comment'
import {RepliesProps} from '../../types/RepliesProps'
import {resetIframeSize} from '../../modules/UIUtils'

const Replies = (props: RepliesProps) => {

    const classes = useStyles()

    const commentEditorRef = useRef<any>(null)

    const handleAccordionChange = () => {
        if (!props.replyClicked && commentEditorRef.current) {
            setTimeout(() => commentEditorRef.current.focus(), 500)
        }
        props.setReplyClicked(!props.replyClicked)
        setTimeout(() => resetIframeSize(props.licenseDetails.trelloIframeContext), 600)
    }

    useEffect(() => {
        let focusTimeout: any
        if (props.replyClicked && commentEditorRef && commentEditorRef.current) {
            focusTimeout = setTimeout(() => {
                commentEditorRef.current.focus()
            }, 200)
        }
        return () => clearTimeout(focusTimeout)
    }, [props.replyClicked])

    return <Box display="flex" flexDirection="row-reverse" mt={'8px'}>
        <Accordion expanded={props.replyClicked} onChange={handleAccordionChange}
                   className={classes.accordionContainer}>
            <AccordionSummary className={classes.accordionSummary}
                              classes={{expanded: classes.accordionSummaryExpanded}}
                              expandIcon={<ExpandMoreIcon/>}
            >
                <Typography>
                    {((props.comment.threadComments && props.comment.threadComments.length > 1) || !props.comment.threadComments || props.comment.threadComments.length === 0) ? `${props.comment.threadComments?.length || 0} Replies` : '1 Reply'}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.repliesContainer}>
                {props.comment.threadComments && props.comment.threadComments.map(threadComment =>
                    <Comment {...props} comment={threadComment} replyComment={true} key={threadComment.id}/>
                )}
                <CommentEditor {...props}
                               defaultText={'Write a reply...'}
                               parentCommentId={props.comment.id || props.comment.idRemovedComment}
                               ref={commentEditorRef}
                               comment={undefined}/>
            </AccordionDetails>
        </Accordion>
    </Box>
}

export default Replies