import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            blockquote: {
                borderLeft: '1px solid #ebebeb',
                color: '#5e6c84',
                marginLeft: 11,
                paddingLeft: 12,
                paddingTop: '-12px'
            },
            codeBlock: {
                fontFamily: 'robotomono',
                fontSize: 14,
                backgroundColor: 'lightgrey',
                borderRadius: 3,
                padding: '8px 12px 8px 12px',
                margin: '12px 8px 12px 8px',
            },
            inlineCodeBlock: {
                fontFamily: 'robotomono',
                fontSize: 14,
                backgroundColor: 'lightgrey',
                color: '#eb5a46'
            },
            strong: {
                fontFamily: 'SF Pro Text Bold',
            },
            selfMention: {
                color: '#0079bf'
            },
            p: {
                fontFamily: 'SF Pro Text Regular',
                whiteSpace: 'pre-wrap'
            },
            image: {
                maxWidth: '100%'
            }
        }
    )
)

export {useStyles}
