const USER_DISMISS_SURVEY_MESSAGE_KEY = 'userDismissSurveyMessage'
const USER_DISMISS_MINI_ONBOARDING = 'userDismissMiniOnboarding'

/**
 * Store the current user on the member's plugin data to know that this user have dismissed the survey message
 * @param licenseDetails data needed to store on the trello API
 */
export const storeUserDismissSurveyMessage = async (licenseDetails: any) => {
    const trelloContext = licenseDetails.trelloIframeContext

    const memberPluginData = await getMemberPluginData(trelloContext, USER_DISMISS_SURVEY_MESSAGE_KEY)
    if (memberPluginData && memberPluginData !== '' && !memberPluginData.userDismissSurveyMessage) {
        memberPluginData.userDismissSurveyMessage = true
        trelloContext.set('member', 'private', USER_DISMISS_SURVEY_MESSAGE_KEY, memberPluginData)
    } else if (!memberPluginData || memberPluginData === '') {
        trelloContext.set('member', 'private', USER_DISMISS_SURVEY_MESSAGE_KEY, {userDismissSurveyMessage: true})
    }
}

/**
 * Check if the current user has dismissed the survey message
 * @param licenseDetails data needed to store on the trello API
 * @return {Promise<boolean>} true if the user has dismissed the message or false otherwise
 */
export const hasUserDismissSurveyMessage = async (licenseDetails: any) => {
    const trelloContext = licenseDetails.trelloIframeContext
    const memberPluginData = await getMemberPluginData(trelloContext, USER_DISMISS_SURVEY_MESSAGE_KEY)
    return memberPluginData && memberPluginData !== '' && memberPluginData.userDismissSurveyMessage
}

/**
 * Get member plugin data
 * @param trelloContext object that contains method and info to get member plugin data
 * @param key string key to get the data
 * @return {Promise<string | any>}
 */
async function getMemberPluginData(trelloContext: any, key: string) {
    return await trelloContext.get('member', 'private', key, '')
}

/**
 * Check if the current user has dismissed the mini onboarding
 * @param trelloContext data needed to store on the trello API
 * @return {Promise<boolean>} true if the user has dismissed or false otherwise
 */
export const hasUserDismissMiniOnboarding = async (trelloContext: any): Promise<boolean> => {
    return await trelloContext.get('member', 'private', USER_DISMISS_MINI_ONBOARDING, false)
}

/**
 * Set to true the mini onboarding flag for the user. It means the user has dismiss the mini onboarding message
 * @param licenseDetails data needed to store on the trello API
 */
export const setUserDismissMiniOnboarding = async (licenseDetails: any) => {
    const trelloContext = licenseDetails.trelloIframeContext
    await trelloContext.set('member', 'private', USER_DISMISS_MINI_ONBOARDING, true)
}
