import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        repliesContainer: {
            padding: '0',
            marginTop: '14px',
            display: 'flex',
            flexDirection: 'column'
        },
        accordionContainer: {
            width: '100%',
            boxShadow: 'none',
            backgroundColor: '#e0e2e8'
        },
        accordionSummary: {
            height: '14px',
            minHeight: '14px',
            padding: 0,
            '& div': {
                margin: 0
            },
            '& p': {
                fontFamily: 'SF Pro Text Semibold',
                lineHeight: '14px',
                fontSize: '12px',
                color: '#172B4E'
            }
        },
        accordionSummaryExpanded: {
            minHeight: '14px !important'
        }
    }),
)

export { useStyles }
