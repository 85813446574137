import {useStyles} from './CommentMarkdownContentStyle'
import React from 'react'
import Markdown from 'react-markdown'
import {CommentMarkdownContentProps} from '../../types/CommentMarkdownContentProps'
import {Link, Typography} from '@material-ui/core'
import {ReactMarkdownProps} from 'react-markdown/src/ast-to-react'
import WarningDialog from './WarningDialog'
import {clearMentionHelper} from '../../modules/TextUtils'

const CommentMarkdownContent = (props: CommentMarkdownContentProps) => {

    const styles = useStyles()

    const {trelloIframeContext} = props

    const memberIsSubscribed = (memberUsername: string) => {
        const member = props.boardAndOrganizationMembers.find(member => `@${member.username}` === memberUsername)
        return !member || member.isSubscribed
    }


    const components = {
        p: (element: any) => <Typography className={styles.p}>{element.children}</Typography>,
        strong: (element: ReactMarkdownProps) => {
            const isMention = element.children.join('').includes('TC_MENTION_TC')
            const isSelfMention = element.children.join('').includes('TC_SELF_MENTION_TC')
            if(isSelfMention || isMention) {
                const memberUsername = element.children.join('').replaceAll(isSelfMention ? 'TC_SELF_MENTION_TC' : 'TC_MENTION_TC', '')
                if (memberIsSubscribed(memberUsername)) return <span className={[styles.strong, isSelfMention ? styles.selfMention : ''].join(' ')}>{memberUsername}</span>
                return <span className={[styles.strong, isSelfMention ? styles.selfMention : ''].join(' ')}>
                    {memberUsername} <WarningDialog trelloContextInfo={trelloIframeContext} isSelfMention={isSelfMention}/>
                </span>
            }
            return <span className={styles.strong}>{element.children}</span>
        },
        a: (element: any) => <Link target={'_blank'} rel="noreferrer" href={element.href.startsWith('http') ? element.href : `http://${element.href}`}>{element.children}</Link>,
        code: (element: any) => {
            const isMultilineMalformed = (element.children.length === 1 && element.children[0] === '' && element.node.data && element.node.data.meta)
            if (element.inline || isMultilineMalformed) {
                return <span className={styles.inlineCodeBlock}>
                    {element.inline ? clearMentionHelper(element.children) : clearMentionHelper(`${element.className.slice(element.className.indexOf('-') + 1)} ${element.node.data.meta}`)}
                </span>
            }
            return <Typography className={styles.codeBlock}>{element.children.map((child: string) => {
                return child.endsWith('```') || child.endsWith('```\n') ? clearMentionHelper(child.slice(0, -4)) : clearMentionHelper(child)
            })}</Typography>
        },
        blockquote: (element: ReactMarkdownProps) => <div
            className={styles.blockquote}>{element.children}</div>,
        img: (element: any) => <img className={styles.image} src={element.src} alt={element.alt || element.src} />
    }

    const commentDisplayContent = props.commentDisplayContent.trim().startsWith('<') ? `&zwnj;${props.commentDisplayContent}` : props.commentDisplayContent
    return <Markdown children={commentDisplayContent} components={components}/>
}

export default CommentMarkdownContent