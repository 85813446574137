import React, {useState} from 'react'
import {Link} from '@material-ui/core'
import {Popup} from '@atlaskit/popup'
import {useStyles} from './WarningDialogStyle'
import {TrackActionEvent} from 'trello-shared-resources/dist'
import {WarningDialogProps} from '../../types/WarningDialogProps'

const WarningDialog = (props: WarningDialogProps) => {

    const classes = useStyles()

    const {trelloContextInfo, isSelfMention} = props

    const [warningDialogOpen, setWarningDialogOpen] = useState(false)

    const onClickHandler = () => {
        setWarningDialogOpen(false)
        TrackActionEvent('Email notification docs link', trelloContextInfo, {
            board_id: trelloContextInfo.board,
            member_id: trelloContextInfo.member,
            action: 'warning_comment'
        })
    }

    const selfWarningLinkHandler = () => {
        TrackActionEvent('Settings link', trelloContextInfo, {
            board_id: trelloContextInfo.board,
            member_id: trelloContextInfo.member,
            action: 'self_warning_dialog'
        })
        trelloContextInfo.modal({
            url: './index.html?apptype=settings',
            fullscreen: true,
            resizable: false,
            title: `${process.env.REACT_APP_TRELLO_APP_NAME} Settings`,
            accentColor: '#EBEDF0'
        })
        setWarningDialogOpen(false)
    }


    const warningDialogContent = <div className={classes.warningMessageContent}>
        Warning! The user(s) you tagged will not receive any notifications about this comment unless they sign up for email notifications. If you'd like them to be notified, they can sign up by following these steps - please send them this <Link
        target="_blank" href={process.env.REACT_APP_NOTIFICATIONS_SIGN_UP_DOCS_LINK} className={classes.warningLink} onClick={onClickHandler}>link</Link>.
    </div>
    const warningDialogContentSelfUser = <div className={classes.warningMessageContent}>
        Warning! You have been tagged in this comment, however you may not have been notified because you have not signed up for email notifications. To sign up for these please enter your email <Link
        className={classes.warningLink} onClick={selfWarningLinkHandler}>here</Link>.
    </div>

    return <Popup
        onClose={() => setWarningDialogOpen(false)}
        content={() => isSelfMention ? warningDialogContentSelfUser : warningDialogContent}
        isOpen={warningDialogOpen}
        placement="top"
        fallbackPlacements={['bottom']}
        trigger={(triggerProps) => (
            <span {...triggerProps}
                  onMouseOver={() => setWarningDialogOpen(true)}>(<span className={classes.emoji}>⚠️</span>️)</span>
        )}
    >

    </Popup>
}

export default WarningDialog