import React from 'react'

import { Grid, Typography } from '@material-ui/core'

import { useStyles } from './EmailNotificationsTextStyles'

import { emailNotificationsTitle } from '../email-notifications-messages'
import { ThreadedCommentOnboardingProperties } from '../../../../types/ThreadedCommentOnboardingProperties'
import RestrictionsSectionMessage from '../../commonComponents/RestrictionsSectionMessage'
import EmailAddressForm from '../../commonComponents/EmailAddressForm'

export const EmailNotificationsText = (props: ThreadedCommentOnboardingProperties) => {
    const classes = useStyles()

    return (
        <Grid item xs={7}>
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center"
                  className={classes.container}>
                <Grid item xs={6}>
                    <Typography className={classes.title}>{emailNotificationsTitle}</Typography>
                    <RestrictionsSectionMessage/>
                    <EmailAddressForm {...props} cancelButtonText="Not today, thanks" submitButtonText="Submit" closeModalOnDismiss={false}/>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default EmailNotificationsText
