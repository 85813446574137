import SectionMessage from '@atlaskit/section-message'
import {Box} from '@material-ui/core'
import React from 'react'
import {useStyles} from './NotificationSettingsStyles'
import {NotificationUpdateSuccessMessageProperties} from '../../../types/NotificationUpdateSuccessMessageProperties'

const NotificationUpdateSuccessMessage = (props: NotificationUpdateSuccessMessageProperties) => {

    const classes = useStyles()

    return <Box className={classes.resultContainer}>
        <SectionMessage
            title={props.titleText}
            appearance="success"
            actions={props.actions}
        >
            <p>
                {props.children}
            </p>
        </SectionMessage>
    </Box>
}

export default NotificationUpdateSuccessMessage