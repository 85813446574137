import React from 'react'
import { Box, Grid, Link, Typography } from '@material-ui/core'

import {
    emailNotificationsStoredBody5,
    emailNotificationsStoredBody6,
    emailNotificationsStoredBody7,
    emailNotificationsStoredBody8,
    emailNotificationsStoredButton,
    emailNotificationsStoredTitle2,
    emailNotificationsTitle
} from '../email-notifications-messages'
import Button from '@atlaskit/button'
import { useStyles } from './EmailNotificationsSuccessStyles'
import { SuccessOrDismissOnboardingProperties } from '../../../../types/SuccessOrDismissOnboardingProperties'
import { TrackActionEvent } from 'trello-shared-resources/dist'
import SuccessMessage from '../../commonComponents/SuccessMessage'

export const EmailNotificationsSuccess = (props: SuccessOrDismissOnboardingProperties) => {
    const classes = useStyles()

    const emailNotificationsDocsLinkHandler = () => {
        const trelloIframeContext = props.licenseDetails.trelloIframeContext
        const trelloContextInfo = trelloIframeContext.getContext()
        TrackActionEvent('Notification docs page link', trelloContextInfo, {
            board_id: trelloContextInfo.board,
            member_id: trelloContextInfo.member
        })
    }

    return (
        <Grid item xs={7}>
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center"
                  className={classes.container}>
                <Grid item xs={6}>
                    <Typography className={classes.title}>{emailNotificationsTitle}</Typography>
                    <SuccessMessage {...props}/>
                    <Typography className={classes.title}>{emailNotificationsStoredTitle2}</Typography>
                    <Box className={classes.content}>
                        <Typography>
                            {emailNotificationsStoredBody5}
                            <Link href={process.env.REACT_APP_TRELLO_APP_EMAIL_NOTIFICATION_DOC_LINK} target="_blank"
                                  onClick={emailNotificationsDocsLinkHandler}>
                                {emailNotificationsStoredBody6}
                            </Link>
                            {emailNotificationsStoredBody7}
                        </Typography>
                        <Typography className={classes.secondParagraph}>{emailNotificationsStoredBody8}</Typography>
                    </Box>
                    <Button appearance="primary" type="submit"
                            onClick={() => props.takeMeToMyBoardClickHandler('email_success')}>{emailNotificationsStoredButton}</Button>
                </Grid>

            </Grid>
        </Grid>
    )
}

export default EmailNotificationsSuccess
