import React, {forwardRef, useEffect, useRef, useState} from 'react'
import Select from '@atlaskit/select'
import {getCardAttachments, uploadAttachmentToCard} from '../../modules/Persistence'
import {AttachmentDialogProps} from '../../types/AttachmentDialogProps'
import {CardAttachment} from '../../types/CardAttachment'
import {SelectOption} from '../../types/SelectOption'
import {Box, Typography} from '@material-ui/core'
import {CustomThemeButton} from '@atlaskit/button'
import {ourButtonTheme, useStyles} from './AttachmentDialogStyle'

const AttachmentDialog = forwardRef((props: AttachmentDialogProps, attachmentContentRef: any) => {

    const classes = useStyles()

    const inputFile = useRef<HTMLInputElement>(null)

    const [attachmentOptions, setAttachmentOptions] = useState<SelectOption[]>([])
    const [attachments, setAttachments] = useState<CardAttachment[]>([])

    const onUploadImage = (event: any) => {
        event.preventDefault()
        props.setKeepAttachmentDialogOpen(true)
        if (inputFile && inputFile.current) {
            inputFile.current.click()
        }
        return false
    }

    const onChangeFile = (event: any) => {
        event.preventDefault()
        const file = event.target.files[0]
        if (file) {
            uploadAttachmentAndAddLink(file)
        }
        event.stopPropagation()
        return false
    }

    const uploadAttachmentAndAddLink = async (file: File) => {
        const attachmentUploaded = await uploadAttachmentToCard(props.licenseDetails, file)
        if (attachmentUploaded) props.handlerAttachmentSelected(attachmentUploaded)
        else props.licenseDetails.trelloIframeContext.alert({
            message: 'Ups! There was a problem uploading the file. Try it again',
            duration: 6
        })
    }

    useEffect(() => {
        const getAttachments = async () => {
            const attachments = await getCardAttachments(props.licenseDetails)
            setAttachments(attachments)
            setAttachmentOptions(attachments.map((attachment: any) => ({value: attachment.id, label: attachment.name})))
        }
        getAttachments()
    }, [props.licenseDetails])

    return <div className={classes.addAttachmentContainer} ref={attachmentContentRef}>
        <Typography className={classes.addAttachmentTitle}>Add a file from</Typography>
        <Box>
            <Typography className={classes.source}>Computer</Typography>
            <input type="file" id="file" ref={inputFile} style={{display: 'none'}} onChange={onChangeFile}/>

            <CustomThemeButton theme={ourButtonTheme} className={classes.uploadButton} onClick={onUploadImage}>
                Upload file
            </CustomThemeButton>
        </Box>
        <Box className={classes.fromTrelloContainer}>
            <Typography className={classes.source}>Trello Card</Typography>
            <Select options={attachmentOptions} className={classes.selectFile}
                    onChange={(value: any) => {
                        if (value) props.handlerAttachmentSelected(attachments.find((attachment: any) => attachment.id === value.value))
                    }
                    }
                    placeholder="Select Attachment"
                    menuPortalTarget={document.body}
                    menuPosition="absolute"
                    onFocus={() => props.setKeepAttachmentDialogOpen(true)}
                    onBlur={() => props.setKeepAttachmentDialogOpen(false)}
                    styles={{
                        menuPortal:
                            /* istanbul ignore next */
                            (base) =>
                            /* istanbul ignore next */
                            ({
                                ...base,
                                zIndex: 9999
                            })
                    }}
            />
        </Box>
    </div>
})

export default React.memo(AttachmentDialog)