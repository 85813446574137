import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {CSSObject} from '@emotion/core'
import {ThemeProps, ThemeTokens} from '@atlaskit/button'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        addAttachmentContainer: {
            width: 392,
            marginTop: -7,
            letterSpacing: 0,
            fontFamily: 'SF Pro Text Regular'
        },
        addAttachmentTitle: {
            color: '#172B4E',
            fontSize: 14,
            fontWeight: 600,
            lineHeight: '16px',
            marginBottom: 22,
            fontFamily: 'SF Pro Text Semibold'
        },
        source: {
            color: '#6C798F',
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '14px',
            fontFamily: 'SF UI Text SemiBold',
            marginBottom: 4
        },
        uploadButton: {
            '& span': {
                color: '#7A869A',
                fontSize: 14,
                lineHeight: '20px',
                marginTop: 6,
                marginLeft: -3,
                fontFamily: 'SF UI Text Regular'
            }
        },
        fromTrelloContainer: {
            marginTop: 23
        },
        selectFile: {
            height: '32px',
            '& > div[class$="control"]': {
                height: '32px',
                minHeight: '32px !important',
            },
            '& > div > div > div': {
                fontFamily: 'SF UI Text Regular',
                color: '#7A869A',
                marginTop: -2
            },
            '& > div > div > div > span': {
                color: '#42526E',
                marginTop: -2
            },
        }
    })
)

const customButtonTheme = {
    default: {
        width: {
            default: '100%',
            active: '100%',
            hover: '100%'
        },
        textAlign: {
            default: 'left',
            active: 'left',
            hover: 'left'
        }
    }
}

function ourButtonTheme(
    currentTheme: (props: ThemeProps) => ThemeTokens,
    themeProps: ThemeProps
): ThemeTokens {
    const {buttonStyles, ...rest} = currentTheme(themeProps)
    return {
        buttonStyles: {
            ...buttonStyles,
            ...extract(themeProps)
        },
        ...rest
    }
}

function extract({
                     mode,
                     /* istanbul ignore next */
                     appearance = 'default',
                     state
                 }: ThemeProps): CSSObject | undefined {
    // @ts-ignore
    const root = customButtonTheme[appearance]

    /* istanbul ignore next */
    if (!root) return undefined

    return Object.keys(root).reduce((acc: { [index: string]: string }, val) => {
        let node = root;
        [val, state, mode].forEach((item) => {
            if (!node[item]) {
                return undefined
            }
            if (typeof node[item] !== 'object') {
                acc[val] = node[item]
                return undefined
            }
            node = node[item]
            return undefined
        })
        return acc
    }, {})
}

export {useStyles, ourButtonTheme}
