import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
        title: {
            color: '#172B4D',
            fontFamily: 'SF UI Text Semibold',
            fontSize: 24,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '28px',
            marginBottom: 36
        },
        subtitle: {
            color: '#172B4D',
            fontFamily: 'SF UI Text Semibold',
            fontSize: 20,
            letterSpacing: 0,
            lineHeight: '24px',
            marginBottom: 16,
            '& > span': {
                verticalAlign: 'middle',
                marginLeft: 12,
                '& > span': {
                    paddingLeft: 8,
                    paddingRight: 8,
                    fontFamily: 'SF Pro Text Semibold'
                }
            }
        },
        emailNotificationText: {
            color: '#172B4D',
            fontFamily: 'SF Pro Text Regular',
            fontSize: 14,
            letterSpacing: 0,
            lineHeight: '21px',
            marginBottom: 16
        },
        emailNotificationUnsubscribeText: {
            marginBottom: 8
        },
        emailNotificationText2: {
            '& label': {
                transform: 'translateX(-5px)'
            }
        },
        emailNotificationDelay: {
            fontSize: 12,
            '& span': {
                verticalAlign: 'bottom',
                marginRight: 7
            },
            marginBottom: 16
        },
        cancelButton: {
            marginRight: 6
        },
        buttonsContainer: {
            marginTop: 24
        },
        emailTextfieldIcon: {
            paddingRight: 6,
            lineHeight: '100%'
        },
        eulaCheckbox: {
            marginLeft: -14,
            marginTop: 24
        },
        eulaCheckboxValidationError: {
            marginLeft: 14
        },
        resultContainer: {
            marginTop: 25,
            marginBottom: 36,
            '& ul': {
                margin: 0
            }
        },
        emailTextfield: {
            maxWidth: '450px !important'
        }

    }
)


export {useStyles}
