import {makeStyles} from '@material-ui/core/styles'
import {createStyles, Theme} from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grid: {
            padding: 36,
            height: '100vh'
        }
    })
)

export {useStyles}